import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNAGoals, StyledContainer, SectionTitle, ImageTextSection } from "./StyledCsNaura"

const NAGoals = props => {
  const ACF = props.data
  console.log(ACF)

  return (
    <StyledNAGoals>
        <StyledContainer>
            <ImageTextSection>
            <div className="imageText ">
                <div className="imageText__image">
                    <GatsbyImage
                    image={getImage(ACF.goalsimage.localFile)}
                    alt={ACF.goalsimage.altText || "Narusa wave down"}
                    title={ACF.goalsimage.title || "Narusa wave down"}
                    className="picture"
                    />
                </div>
                <div className="imageText__content">
                    <SectionTitle>{ACF.ttitlegoals}</SectionTitle>
                    <div className="imageText__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.textgoals,
                    }}/>
                    <SectionTitle>{ACF.ttitlestrategy}</SectionTitle>
                    <div className="imageText__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.textstrategy,
                    }}/>
                </div>
            </div>
            </ImageTextSection>
        </StyledContainer>
    </StyledNAGoals>
  )
}
export default NAGoals
