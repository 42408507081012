import React, {useState} from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNASession, StyledVideosSection, ImageTextSection,  StyledContainer, SectionTitle } from "./StyledCsNaura"
import Play from "../../../images/svg/play.svg"

const NASession = props => {
  const ACF = props.data

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }

  return (
    <StyledNASession>
        <div className="session__photo">
            <GatsbyImage
            image={getImage(props.wave.localFile)}
            alt={props.wave.altText || "Narusa wave down"}
            title={props.wave.title || "Narusa wave down"}
            className="session__wave"
            />
            <div className="session__photoContent">
                <StyledContainer>
                  <ImageTextSection>
                      <div className="imageText imageText--right">
                          <div className="imageText__image ">
                              <GatsbyImage
                              image={getImage(ACF.sessionimage.localFile)}
                              alt={ACF.sessionimage.altText || "Sesja plenerowa"}
                              title={ACF.sessionimage.title || "Sesja plenerowa"}
                              className="picture"
                              />
                          </div>
                          <div className="imageText__content">
                              <SectionTitle>Materiały firmowe</SectionTitle>
                              <div className="imageText__text"
                              dangerouslySetInnerHTML={{
                                  __html: ACF.sessiontext,
                              }}/>
                          </div>
                      </div>
                  </ImageTextSection>
                </StyledContainer>
            </div>
        </div>
        <StyledContainer>
          <div className="session__video">
              <StyledVideosSection>
                {ACF.videofiles.map((item, index) => (
                    <a
                      key={index}
                      className="StyledVideosSection__image"
                      onClick={() => openLightboxOnSlide(index+1)}
                    >
                      <img src={item.ytimage.localFile.childImageSharp.fixed.src} alt={item.ytimage.altText || 'Narusa'} title={item.ytimage.title || "Narusa"}/>
                      {/* item.posterImage */}
                      <Play className="playIcon"/>
                    </a>
                  ))}

                  {/* <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={ ACF.videofiles.map((item, index) => item.yturl) }
                    slide={lightboxController.slide}
                  /> */}
              </StyledVideosSection>
              <div className="session__videoText">
                <SectionTitle>Reportaż i relacja video</SectionTitle>
                <div className="imageText__text"
                  dangerouslySetInnerHTML={{
                      __html: ACF.videotext,
                  }}/>
              </div>
          </div>
        </StyledContainer>
        <div className="backgroundTree">
            <GatsbyImage
                image={getImage(props.tree.localFile)}
                alt={props.tree.altText || "background tree"}
                title={props.tree.title || "background tree"}
            />
        </div>
    </StyledNASession>
  )
}
export default NASession
