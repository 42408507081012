import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNAGoals, StyledContainer, SectionTitle, ImageTextSection } from "./StyledCsNaura"

const NAStorytelling = props => {
  const ACF = props.data


  return (
    <StyledNAGoals>
        <StyledContainer>
            <ImageTextSection>
            <div className="imageText ">
                <div className="imageText__image">
                    <GatsbyImage
                    image={getImage(ACF.image.localFile)}
                    alt={ACF.image.altText || "Narusa wave down"}
                    title={ACF.image.title || "Narusa wave down"}
                    className="picture"
                    />
                </div>
                <div className="imageText__content">
                    <SectionTitle>{ACF.title}</SectionTitle>
                    <div className="imageText__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.text,
                    }}/>
                </div>
            </div>
            </ImageTextSection>
        </StyledContainer>
    </StyledNAGoals>
  )
}
export default NAStorytelling
