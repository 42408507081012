import React from "react"
import Layout from "../../components/layout"

import type { HeadFC, PageProps } from "gatsby"
import { SEO } from "../../components/seo"


import NABanner from "../../components/organisms/CsNarusa/NABanner"
import NAGoals from "../../components/organisms/CsNarusa/NAGoals"
import NAActions from "../../components/organisms/CsNarusa/NAActions"
import NANaming from "../../components/organisms/CsNarusa/NANaming"
import NABigIdea from "../../components/organisms/CsNarusa/NABigIdea"
import NAStorytelling from "../../components/organisms/CsNarusa/NAStorytelling"
import NALogo from "../../components/organisms/CsNarusa/NALogo"
import NAKey from "../../components/organisms/CsNarusa/NAKey"
import NAWebsite from "../../components/organisms/CsNarusa/NAWebsite"
import NASession from "../../components/organisms/CsNarusa/NASession"
import NAEffects from "../../components/organisms/CsNarusa/NAEffects"

import NextItem from "../../components/molecules/NextItem"
import PreviousItem from "../../components/molecules/PreviousItem"
import NextPreview from "../../components/atoms/NextPreview"


export default function CaseStudy(props) {
  const ACF = props.pageContext.ACF_narusa
  const SEO = props.pageContext.seo




  return <Layout seoData={SEO} seoUrl={props.pageContext.slug}>
     <div style={{ overflowX: 'hidden'}}>
      <NABanner data={ACF.banner} wave={ACF.wavedown} />
      <NAGoals data={ACF.csgoals} />
      <NAActions data={ACF.csactions} tree={ACF.backgroundtree}/>
      <NANaming data={ACF.narusaNaming} />
      <NABigIdea data={ACF.narusaBigidea} />
      <NAStorytelling data={ACF.narusaStorytelling} />
      <NALogo data={ACF.narusaSygnet} logo={ACF.logogold} wave={ACF.wavedown} />
      <NAKey data={ACF.naursaKeyvisual} tree={ACF.backgroundtree} />
      <NAWebsite data={ACF.narusaWebsite} />
      <NASession data={ACF.narusaVideo} wave={ACF.waveup} tree={ACF.backgroundtree} />
      <NAEffects data={ACF.narusaCampaign} wave={ACF.waveup} effects={ACF.narusaEffectstext} />
      <NextPreview>
        {props.pageContext.previous && (
          <PreviousItem
            prevLink={props.pageContext.previous.uri}
            prevName="Poprzednie Case Study"
          />
        )}
        {props.pageContext.next && (
          <NextItem
            nextLink={props.pageContext.next.uri}
            nextName="Następne Case Study"
          />
        )}
      </NextPreview>
      </div>
    </Layout>
}
export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
