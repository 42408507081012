import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNABanner, StyledContainer } from "./StyledCsNaura"

const NABanner = props => {
  const ACF = props.data

  return (
    <StyledNABanner>
      <div className="banner">
        <GatsbyImage
          image={getImage(ACF.backgroundimage.localFile)}
          alt={ACF.backgroundimage.altText || "Narusa banner image"}
          title={ACF.backgroundimage.title || "Narusa banner image"}
          className="poly"
        />
        <div className="banner__wrapper">
          <div className="banner__top">
            <GatsbyImage
              image={getImage(ACF.logo.localFile)}
              // alt={ACF.logo.altText || "Narusa banner image"}
              alt={ACF.logo.altText || "Narusa banner image"}
              title={ACF.logo.title || "Narusa banner image"}
              className="banner__logo"
            />
            <h1 className="banner__title">{ACF.titile}</h1>

          </div>
          <div className="banner__text">
            <span className="bold">case study</span>
            {ACF.casestudy}</div>
        </div>


      </div>
      <div className="banner__bottom" >
        <GatsbyImage
          image={getImage(props.wave.localFile)}
          alt={props.altText || "Narusa wave down"}
          title={props.title || "Narusa wave down"}
          className="bb__wave"
        />
        <StyledContainer>
        <div className="bb__bellow">
          <div className="elements">
            <div className="element">
              <div className="label">
                Marka:
              </div>
              <div className="text">
                Narusa Leśna Przystań
              </div>
            </div>
            <div className="element">
              <div className="label">
                Branża:
              </div>
              <div className="text">
                deweloperska, HoReCa
              </div>
            </div>
            <div className="element">
              <div className="label">
                Typ projektu:
              </div>
              <div className="text">
                branding
              </div>
            </div>
          </div>
          <div className="description"
            dangerouslySetInnerHTML={{
            __html: ACF.bellowtext,
          }} />

        </div>
        </StyledContainer>
      </div>

    </StyledNABanner>
  )
}
export default NABanner
