import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNAEffects,  StyledContainer, SectionTitle } from "./StyledCsNaura"

const NAEffects = props => {
  const ACF = props.data


  const campainItems  = ACF.campaignitem.map((item, index) =>

    <div key={index} className="campain__item">
        <SectionTitle className="ci__title">
            {item.title}
        </SectionTitle>
        <div className="ci__text">
            {item.text}
        </div>
    </div>
    )


  return (
    <StyledNAEffects>
        <div className="backgroundImage">
            <GatsbyImage
            image={getImage(ACF.campainbackground.localFile)}
            alt={ACF.campainbackground.altText || "Kampania"}
            title={ACF.campainbackground.title || "Kampania"}
            className="image"
            />
        </div>
        <div className="campain" >
            <StyledContainer>
                <div className="campain__wrapper">
                    <div className="campain__items">
                        {campainItems}
                    </div>
                    <div className="campain__summary">
                        <div className="campain__text"dangerouslySetInnerHTML={{
                            __html: ACF.campainsummary,
                        }} />
                    </div>
                </div>
            </StyledContainer>
        </div>
        <div className="effects">
            <StyledContainer>
                <SectionTitle textAlignment="center">Efekty</SectionTitle>
                <div className="effects__text"dangerouslySetInnerHTML={{
                    __html: props.effects,
                }} />
            </StyledContainer>
        </div>
        <GatsbyImage
            image={getImage(props.wave.localFile)}
            alt={props.wave.altText || "Narusa wave down"}
            title={props.wave.title || "Narusa wave down"}
            className="bottomWave"
            />
    </StyledNAEffects>
  )
}
export default NAEffects
