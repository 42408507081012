import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

const NextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const NextLink = styled.div`
  cursor: pointer;
  display: ${props => props.display || "block"};
  text-align: right;
  margin-left: 1rem;

  a {
    font-size: 1rem;
    font-weight: ${props => props.fontWeight || "700"};
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.color || "#F19206"};
    transition: all 0.3s ease 0s;
    text-decoration: none;
    text-align: right;

    @media ${props => props.theme.sizes.tablet} {
      font-size: 1.5rem;
    }

    @media ${props => props.theme.sizes.laptopL} {
      font-size: ${props => props.size || "1.8rem"};
    }
  }
  &:hover {
    a {
      transition: all 0.3s ease 0s;
      color: ${props => props.theme.colors.text};
    }
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
`

const NextItem = props => {
  return (
    <NextWrapper>
      <NextLink>
        <Link to={props.nextLink}>{props.nextName + " >>"}</Link>
      </NextLink>
    </NextWrapper>
  )
}

export default NextItem
