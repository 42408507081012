import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNALogo, StyledContainer, SectionTitle, ImageTextSection } from "./StyledCsNaura"

const NALogo = props => {
  const ACF = props.data




    const SygnetArray  = ACF.sygnetimages.map((sygnet, index) =>
        <div key={index} className={"sygnet__element sygnet__element--" + (index + 1)}>
            <GatsbyImage
                image={getImage(sygnet.image.localFile)}
                alt={sygnet.image.altText || "Narusa wave down"}
                title={sygnet.image.title || "Narusa wave down"}
                className="sygnet__item"
            />
            <div className="sign">
                <span className="plus">+</span>
                <span className="equl">=</span>
            </div>
        </div>
  )




  return (
    <StyledNALogo>
        <div className="sygnet">
            <GatsbyImage
            image={getImage(props.wave.localFile)}
            alt={props.wave.altText || "Narusa wave down"}
            title={props.wave.title || "Narusa wave down"}
            className="sygnet__wave"
            />
            <div className="sygnet__content">
                <StyledContainer>
                    <SectionTitle textAlignment='center'>
                        Sygnet
                    </SectionTitle>
                    <div className="sygnet__elements">
                        {SygnetArray}
                    </div>
                </StyledContainer>
            </div>
        </div>
        <StyledContainer>
            <ImageTextSection>
            <div className="imageText imageText--right">
                <div className="imageText__image">
                    <GatsbyImage
                    image={getImage(props.logo.localFile)}
                    alt={props.logo.altText || "Narusa wave down"}
                    title={props.logo.title || "Narusa wave down"}
                    className="picture"
                    />
                </div>
                <div className="imageText__content">
                    <SectionTitle>Logo</SectionTitle>
                    <div className="imageText__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.logotext,
                    }}/>
                </div>
            </div>
            </ImageTextSection>
        </StyledContainer>
    </StyledNALogo>
  )
}
export default NALogo
