import React from "react"


import { StyledNABigIdea, StyledContainer } from "./StyledCsNaura"

const NABigIdea = props => {
  const ACF = props.data
  console.log(ACF)

  const BigIdeaItems = ACF.sections.map((section, index) =>
    <div key={index} className="bigIdea__item">
        <StyledContainer>
            <h3 className="title">{section.title}</h3>
            {section.claim && (
            <div className="claim">{section.claim}</div>
            )}
            <div className="text">{section.text}</div>
        </StyledContainer>
    </div>
  )

  return (
    <StyledNABigIdea>
        <div className="bigIdea">
            <div className="bigIdea__items">
                {BigIdeaItems}
            </div>
        </div>
    </StyledNABigIdea>
  )
}
export default NABigIdea
