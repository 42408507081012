import styled from "styled-components"

let colorPrimary = "#D6B783"
let colorSecondary = "#F8F8F8"
let colorText = "#373737"

export const StyledContainer = styled.div`
  margin: 0 10vw;

  @media ${props => props.theme.sizes.laptop} {
    margin: 0 5vw;
  }
  @media ${props => props.theme.sizes.laptop17} {
    margin: 0 10vw;
  }
`

export const SectionTitle = styled.h2`
  font-size: 2.2rem;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 600;
  color: ${props => props.color || colorPrimary};
  margin: 0 0 2rem;
  text-align: left;

  @media ${props => props.theme.sizes.laptopM} {
    font-size: 2.5rem;
    text-align: ${props => props.textAlignment || "left"};
  }
`

export const ImageTextSection = styled.div`
  .imageText {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

    @media ${props => props.theme.sizes.laptopM} {
      flex-direction: row;
      padding: 5rem 0;
    }

    &__image {
      width: 100%;
      position: relative;
      margin: 0 0 2rem 0;

      @media ${props => props.theme.sizes.laptopM} {
        width: 50%;
        padding: 0 3.5% 0 0;
        margin: unset;
        display: flex;
      }

      &::after {
        content: "";
        position: absolute;
        width: 95%;
        height: 106%;
        top: -3.5%;
        left: -5%;
        border: 2px solid ${colorPrimary};
        z-index: -1;
      }
    }
    &__text {
      margin-bottom: 0.5rem;
    }
    &__content {
      width: 100%;
      font-family: ${props => props.theme.fonts.sourceSans};
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media ${props => props.theme.sizes.laptopM} {
        width: 50%;
        padding: 0 0 0 3.5%;
      }
    }
    &--right {
      flex-direction: column;

      @media ${props => props.theme.sizes.laptopM} {
        flex-direction: row-reverse;
      }

      .imageText__image {
        @media ${props => props.theme.sizes.laptopM} {
          padding: 0 0 0 3.5%;
        }

        &::after {
          left: unset;
          right: -5%;
        }
      }
      .imageText__content {
        padding: 0 3.5% 0 0;
      }
    }
  }
`

export const WideImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${props => props.theme.sizes.laptopM} {
    flex-direction: row;
  }

  .wideImage {
    &__image {
      width: 100%;

      @media ${props => props.theme.sizes.laptopM} {
        width: 36%;

        .gatsby-image-wrapper {
          margin-right: -45%;
        }
      }
    }
    &__content {
      background: ${props => props.backgroundColor || "unset"};
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 5rem 10vw;

      @media ${props => props.theme.sizes.laptopM} {
        width: 64%;
        padding: 12rem 10vw 12rem 18vw;
      }
    }
    &__text {
      font-size: 1.1rem;
      font-weight: 400;
      font-family: ${props => props.theme.fonts.sourceSans};
      color: ${props => props.textColor || "#373737"};
    }
  }
  &.wideImage--left {
    flex-direction: column;

    @media ${props => props.theme.sizes.laptopM} {
      flex-direction: row-reverse;

      .gatsby-image-wrapper {
        margin-right: unset;
        margin-left: -40%;
      }
    }

    .wideImage__content {
      padding: 5rem 10vw;

      @media ${props => props.theme.sizes.laptopM} {
        padding: 12rem 18vw 12rem 10vw;
      }
    }
  }
`

export const StyledNABanner = styled.section`
  position: relative;

  .banner {
    height: 90vh;
    position: relative;


    @media ${props => props.theme.sizes.laptopM} {
      height: 90vh;
    }

    .gatsby-image-wrapper {
      height: 90vh;

      @media ${props => props.theme.sizes.laptopM} {
        height: 90vh;
      }
    }

    &__wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.5);
    }
    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__logo {
      height: unset !important;

      @media ${props => props.theme.sizes.dekstop} {
        max-width: 220px;
      }
    }
    &__title {
      font-size: 2.2rem;
      text-align: center;
      color: ${colorPrimary};
      font-weight: 400;

      @media ${props => props.theme.sizes.laptopM} {
        font-size: 5rem;
      }
    }
    &__text {
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      color: ${colorPrimary};
      font-family: ${props => props.theme.fonts.primary};

      .bold {
        font-size: 2rem;
        font-weight: 600;

        @media ${props => props.theme.sizes.laptopM} {
          font-size: 2.5rem;
        }
      }
    }
    &__bottom {
      position: relative;
      font-family: ${props => props.theme.fonts.primary};
      padding-bottom: 3rem;
      background: ${colorSecondary};

      @media ${props => props.theme.sizes.laptopM} {
        background: unset;
      }

      .bb {
        &__wave {
          margin: 0;
          position: absolute;
          top: -4rem;
          z-index: -1;
          padding-bottom: 4rem;

          @media ${props => props.theme.sizes.laptopM} {
            padding-bottom: 17rem;
          }
          @media ${props => props.theme.sizes.laptopL} {
            padding-bottom: 9rem;
          }
          @media ${props => props.theme.sizes.desktop} {
            padding-bottom: 4rem;
          }
        }
        &__bellow {
          padding: 3rem 0;
          display: flex;
          flex-direction: column;
          z-index: 10;

          @media ${props => props.theme.sizes.laptopM} {
            flex-direction: row;
            padding: 7rem 0 6rem;
          }

          .elements {
            min-width: 22%;

            .element {
              margin-bottom: 1rem;

              .label {
                color: ${colorPrimary};
                font-size: 2rem;
                font-weight: 600;
              }
              .text {
                font-size: 1.6rem;
              }
            }
          }

          .description {
            font-size: 1.1rem;
            font-weight: 400;
            font-family: ${props => props.theme.fonts.sourceSans};

            z-index: 10;

            @media ${props => props.theme.sizes.laptopM} {
              padding-left: 12%;
            }
          }
        }
      }
    }
  }
`

export const StyledNAGoals = styled.section``

export const StyledNAActions = styled.section`
  position: relative;
  padding: 2rem 0;

  @media ${props => props.theme.sizes.laptopM} {
    padding: 10rem 0 5rem;
  }

  .actions {
    &__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      @media ${props => props.theme.sizes.laptopM} {
        padding: 4rem 0;
      }
    }
    &__item {
      width: 100%;
      margin-bottom: 4rem;

      @media ${props => props.theme.sizes.laptopM} {
        width: 33%;

        &:nth-of-type(3n -2) {
          padding-right: 6rem;
        }
        &:nth-of-type(3n -1) {
          padding-right: 3rem;
          padding-left: 3rem;
        }
        &:nth-of-type(3n) {
          padding-left: 6rem;
        }
      }

      .ai {
        &__title {
          font-size: 1.8rem;
          font-weight: 600;
          font-family: ${props => props.theme.fonts.primary};
          margin-bottom: 1rem;
        }
        &__text {
          font-size: 1.2rem;
          font-family: ${props => props.theme.fonts.sourceSans};
        }
      }
    }
  }

  .backgroundTree {
    position: absolute;
    top: -10%;
    right: -10%;
    width: 80%;
    height: 110%;
    display: none;

    @media ${props => props.theme.sizes.laptopM} {
      display: block;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
`
export const StyledNANaming = styled.section``
export const StyledNABigIdea = styled.section`
  margin: 5rem 0;

  .bigIdea {
    &__items {
    }
    &__item {
      padding: 3rem 0;

      &:nth-of-type(1) {
        background: ${colorText};

        .text {
          color: #fff;
        }
      }
      &:nth-of-type(2) {
        background: ${colorSecondary};
      }
      .title {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        color: ${colorPrimary};
        font-family: ${props => props.theme.fonts.primary};
        margin: 0;

        @media ${props => props.theme.sizes.laptopM} {
          font-size: 2.5rem;
        }
      }
      .claim {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        font-family: ${props => props.theme.fonts.primary};

        @media ${props => props.theme.sizes.laptopM} {
          font-size: 2.5rem;
        }
      }
      .text {
        margin-top: 1.5rem;
        font-size: 1.15rem;
        text-align: center;
        font-family: ${props => props.theme.fonts.sourceSans};

        @media ${props => props.theme.sizes.laptopM} {
          font-size: 1.2rem;
        }
      }
    }
  }
`
export const StyledNALogo = styled.section`
  position: relative;
  margin: 2rem 0;

  @media ${props => props.theme.sizes.laptopM} {
    margin: 5rem 0;
    }

  .imageText--right {
    flex-direction: column-reverse;

    @media ${props => props.theme.sizes.laptopM} {
      flex-direction: row-reverse;
    }
  }

  .imageText__image {
    display: flex;
    justify-content: center;

    &::after {
      display: none;
    }
  }
  .sygnet {
    &__wave {
      display: none;

      @media ${props => props.theme.sizes.laptopM} {
        display: block;
        min-height: 50vh;
      }
      @media ${props => props.theme.sizes.laptopL} {
        min-height: 60vh;
      }
    }
    &__content {
      background: ${colorSecondary};
      padding: 4rem 0;

      @media ${props => props.theme.sizes.laptopM} {
        padding: unset;
        flex-direction: row;
        position: absolute;
        top: 5rem;
        width: 100%;
      }
    }

    &__elements {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 4rem 0;

      @media ${props => props.theme.sizes.laptopM} {
        margin: unset;
        flex-direction: row;
      }
    }
    &__element {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media ${props => props.theme.sizes.laptopM} {
        flex-direction: row;
      }

      .sign {
        font-family: ${props => props.theme.fonts.primary};
        font-weight: 600;
        font-size: 3.5rem;
        color: ${colorPrimary};

        @media ${props => props.theme.sizes.laptopM} {
          margin-left: 2rem;
        }
        @media ${props => props.theme.sizes.laptopL} {
          margin-left: 4rem;
        }
      }
      &--2,
      &--1 {
        .equl {
          display: none;
        }
      }
      &--3 {
        .equl {
          display: flex;
        }
        .plus {
          display: none;
        }
      }
      &--4 {
        .sign {
          display: none;
        }
      }
    }
  }
  .imageText__image {
    margin-top: 2rem;
  }
`
export const StyledNAKey = styled.section`
  position: relative;
  overflow: hidden;

  .backgroundTree {
    position: absolute;
    bottom: -10%;
    left: -40%;
    z-index: -1;
    display: none;

    @media ${props => props.theme.sizes.laptopM} {
          display: block;
        }
  }
`
export const StyledNAWebsite = styled.section`
  margin: 5rem 0;
`
export const StyledNASession = styled.section`
  margin: 5rem 0 0;
  position: relative;
  overflow: hidden;

  @media ${props => props.theme.sizes.laptopM} {
    padding-bottom: 8rem;
  }

  .session {
    &__wave {
      z-index: -5;
      padding-bottom: 5rem;
      height: 95vh;

      @media ${props => props.theme.sizes.mobileL} {
      height: 70vh;
      }
      @media ${props => props.theme.sizes.tablet} {
      height: 80vh;
      }
      @media ${props => props.theme.sizes.laptopM} {
        height: unset;
      }
    }
    &__photo {
      position: relative;
    }
    &__photoContent {
      width: 100%;
      position: absolute;
        top: 5rem;

      @media ${props => props.theme.sizes.laptopM} {
        position: absolute;
        top: 5rem;
      }
    }
    &__video {
      display: flex;
      flex-direction: column-reverse;

      @media ${props => props.theme.sizes.laptopM} {
        flex-direction: row;
      }
    }
    &__videoText {
      width: 100%;
      padding: 3rem 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media ${props => props.theme.sizes.laptopM} {
        width: 50%;
        padding: 0 0 0 3.5%;
      }
    }
  }

  .backgroundTree {
    position: absolute;
    bottom: -10%;
    right: -20%;
    z-index: -10;
    max-width: 1200px;
    display: none;

    @media ${props => props.theme.sizes.laptopM} {
      display: block;
    }
  }
`
export const StyledVideosSection = styled.div`
  width: 100%;
  padding: 0 3.5% 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2rem;

  @media ${props => props.theme.sizes.laptopM} {
    width: 50%;
    flex-direction: row;
    margin-top: -5%;
  }

  .StyledVideosSection {
    &__image {
      position: relative;
      cursor: pointer;
      width: 100%;
      margin: 0 0 1rem;

      @media ${props => props.theme.sizes.laptopM} {
        width: 50%;

        &:nth-of-type(1) {
          width: 100%;
          margin: 0 0 1rem;
        }
        &:nth-of-type(2) {
          width: calc(50% - 0.5rem);
          margin: 0 0.5rem 0 0;
        }
        &:nth-of-type(3) {
          width: calc(50% - 0.5rem);
          margin: 0 0 0 0.5rem;
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        z-index: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .playIcon {
        position: absolute;
        top: calc(50% - 48.5px);
        left: calc(50% - 48.5px);
        z-index: 10;
      }
    }
  }
`
export const StyledNAEffects = styled.section`
  margin: 5rem 0;
  position: relative;
  overflow: hidden;

  .backgroundImage {
    position: relative;
    z-index: -1;
    display: none;

    @media ${props => props.theme.sizes.desktop} {
      display: block;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .campain {
    background: ${colorText};
    z-index: 10;

    @media ${props => props.theme.sizes.desktop} {
      position: absolute;
      top: 0rem;
      background: unset;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 5rem 0;

      @media ${props => props.theme.sizes.laptopM} {
        flex-direction: row;
        padding: 4rem 0;
      }
    }

    &__items {
      width: 100%;

      @media ${props => props.theme.sizes.laptopM} {
        width: 50%;
      }
    }
    &__summary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media ${props => props.theme.sizes.laptopM} {
        width: 50%;
        margin-left: 2rem;
        justify-content: flex-end;
      }
    }
    &__text {
      border: 5px solid ${colorPrimary};
      color: ${colorPrimary};
      font-size: 1.6rem;
      font-weight: 700;
      font-family: ${props => props.theme.fonts.primary};
      text-align: center;
      padding: 3rem 1.5rem;

      @media ${props => props.theme.sizes.laptopM} {
        padding: 3rem 1.5rem;
        font-size: 1.6rem;
      }
      @media ${props => props.theme.sizes.desktop} {
        padding: 4rem 2rem;
        font-size: 1.8rem;
      }

      strong {
        color: #fff;
      }
    }

    &__item {
      margin-bottom: 3rem;

      .ci {
        &__title {
        }
        &__text {
          color: #fff;
          font-size: 1.1rem;
          font-weight: 400;
          font-family: ${props => props.theme.fonts.sourceSans};
        }
      }
    }
  }
  .effects {
    width: 100%;
    z-index: 10;
    margin-top: 2rem;
    position: absolute;
    bottom: 2rem;

    @media ${props => props.theme.sizes.laptopM} {
      position: absolute;
      bottom: 10rem;
      text-align: center;
      margin-top: unset;
    }

    &__text {
      font-size: 1.1rem;
      font-weight: 400;
      font-family: ${props => props.theme.fonts.sourceSans};
    }
  }
  .bottomWave {
    height: 100vh;
    margin-top: 2rem;

    @media ${props => props.theme.sizes.mobileL} {
      height: 70vh;
    }


    @media ${props => props.theme.sizes.laptopM} {
      margin-top: 5rem;
      margin-bottom: -10rem;
      height: unset;
    }
  }
`
