import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

const PreviousWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const PreviousLink = styled.div`
  cursor: pointer;
  display: ${props => props.display || "block"};
  margin-right: 1rem;

  a {
    font-size: 1rem;
    font-weight: ${props => props.fontWeight || "700"};
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.color || "#F19206"};
    transition: all 0.3s ease 0s;
    text-decoration: none;
    text-align: left;

    @media ${props => props.theme.sizes.tablet} {
      font-size: 1.5rem;
    }

    @media ${props => props.theme.sizes.laptopL} {
      font-size: ${props => props.size || "1.8rem"};
    }
  }
  &:hover {
    a {
      transition: all 0.3s ease 0s;
      color: ${props => props.theme.colors.text};
    }
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
`

const PreviousItem = props => {
  return (
    <PreviousWrapper>
      <PreviousLink>
        <Link to={props.prevLink}>{"<< " + props.prevName}</Link>
      </PreviousLink>
    </PreviousWrapper>
  )
}

export default PreviousItem
