import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNAActions,  StyledContainer, SectionTitle } from "./StyledCsNaura"

const NAActions = props => {
  const ACF = props.data

  const actionsItems  = ACF.items.map((item, index) =>

    <div key={index} className="actions__item">
        <div className="ai__title">
            {item.title}
        </div>
        <div className="ai__text">
            {item.text}
        </div>
    </div>
  )

  return (
    <StyledNAActions>
        <div className="actions">
            <StyledContainer>
                <SectionTitle textAlignment='center'>Działania</SectionTitle>
                <div className="actions__items">
                    {actionsItems}
                </div>
            </StyledContainer>
            <div className="backgroundTree">
                <GatsbyImage
                    image={getImage(props.tree.localFile)}
                    alt={props.tree.altText || "background tree"}
                    title={props.tree.title || "background tree"}
                />
            </div>
        </div>
    </StyledNAActions>
  )
}
export default NAActions
