import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNANaming,  StyledContainer, SectionTitle, ImageTextSection } from "./StyledCsNaura"

const NANaming = props => {
  const ACF = props.data
  console.log(ACF)

  return (
    <StyledNANaming>
        <StyledContainer>
            <ImageTextSection>
            <div className="imageText imageText--right">
                <div className="imageText__image">
                    <GatsbyImage
                    image={getImage(ACF.image.localFile)}
                    alt={ACF.image.altText || "Narusa wave down"}
                    title={ACF.image.title || "Narusa wave down"}
                    className="picture"
                    />
                </div>
                <div className="imageText__content">
                    <SectionTitle>{ACF.namingTitle}</SectionTitle>
                    <div className="imageText__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.namingText,
                    }}/>
                    <SectionTitle>{ACF.personalityTitile}</SectionTitle>
                    <div className="imageText__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.personalityText,
                    }}/>
                </div>
            </div>
            </ImageTextSection>
        </StyledContainer>
    </StyledNANaming>
  )
}
export default NANaming
