import React from "react"
import styled from "styled-components"

const StyledNextPreview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 5vw;

  @media ${props => props.theme.sizes.tablet} {
    justify-content: space-around;
    padding: 4rem 0 2rem;
  }
`

const NextPreview = props => {
  return <StyledNextPreview>{props.children}</StyledNextPreview>
}

export default NextPreview
