import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNAKey, WideImageSection, ImageTextSection,  StyledContainer, SectionTitle } from "./StyledCsNaura"

const NAKey = props => {
  const ACF = props.data

  return (
    <StyledNAKey>
        <WideImageSection
         className="wideImage"
         backgroundColor="#373737"
         textColor='#fff'
        >
            <div className="wideImage__image">
                <GatsbyImage
                image={getImage(ACF.keyimage.localFile)}
                alt={ACF.keyimage.altText || "Narusa wave down"}
                title={ACF.keyimage.title || "Narusa wave down"}
                className="sygnet__wave"
                />
            </div>
            <div className="wideImage__content">
                <SectionTitle>Key visual</SectionTitle>
                <div className="wideImage__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.keytext,
                    }}/>
            </div>
        </WideImageSection>
        <StyledContainer>
            <ImageTextSection>
                <div className="imageText imageText--right">
                    <div className="imageText__image ">
                        <GatsbyImage
                        image={getImage(ACF.companyimage.localFile)}
                        alt={ACF.companyimage.altText || "Narusa wave down"}
                        title={ACF.companyimage.title || "Narusa wave down"}
                        className="picture"
                        />
                    </div>
                    <div className="imageText__content">
                        <SectionTitle>Materiały firmowe</SectionTitle>
                        <div className="imageText__text"
                        dangerouslySetInnerHTML={{
                            __html: ACF.companytext,
                        }}/>
                    </div>
                </div>
            </ImageTextSection>
        </StyledContainer>
        <div className="backgroundTree">
            <GatsbyImage
                image={getImage(props.tree.localFile)}
                alt={props.tree.altText || "background tree"}
                title={props.tree.title || "background tree"}
            />
        </div>
    </StyledNAKey>
  )
}
export default NAKey
