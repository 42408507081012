import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNAWebsite, WideImageSection,  StyledContainer, SectionTitle } from "./StyledCsNaura"

const NAWebsite = props => {
  const ACF = props.data


  return (
    <StyledNAWebsite>
        <WideImageSection
         className="wideImage wideImage--left"
         backgroundColor="#D6B783"
         textColor='#fff'
        >
            <div className="wideImage__image">
                <GatsbyImage
                image={getImage(ACF.langingpageimage.localFile)}
                alt={ACF.langingpageimage.altText || "Narusa wave down"}
                title={ACF.langingpageimage.title || "Narusa wave down"}
                className="sygnet__wave"
                />
            </div>
            <div className="wideImage__content">
                <SectionTitle
                color='#fff'
                >Key visual</SectionTitle>
                <div className="wideImage__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.landingpagetext,
                    }}/>
            </div>
        </WideImageSection>
        <WideImageSection
         className="wideImage "
        >
            <div className="wideImage__image">
                <GatsbyImage
                image={getImage(ACF.websiteimage.localFile)}
                alt={ACF.websiteimage.altText || "Narusa wave down"}
                title={ACF.websiteimage.title || "Narusa wave down"}
                className="sygnet__wave"
                />
            </div>
            <div className="wideImage__content">
                <SectionTitle>Strona internetowa</SectionTitle>
                <div className="wideImage__text"
                    dangerouslySetInnerHTML={{
                        __html: ACF.websitetext,
                    }}/>
            </div>
        </WideImageSection>

    </StyledNAWebsite>
  )
}
export default NAWebsite
